<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="60%"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="surveyId"
            class="body-2"
          >
            <i>#{{ survey.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="title"
              >
                <v-text-field
                  v-model="survey.title"
                  hide-details="auto"
                  label="Título"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="nameOfResponsible"
              >
                <v-text-field
                  v-model="survey.nameOfResponsible"
                  hide-details="auto"
                  label="Nome do responsável"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="emailOfResponsible"
              >
                <v-text-field
                  v-model="survey.emailOfResponsible"
                  hide-details="auto"
                  label="E-mail do responsável"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="startDate"
                  >
                    <v-text-field
                      v-model="formatedStartDate"
                      v-mask="'##/##/####'"
                      label="Data início"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="survey.startDate = dateFormatIso(formatedStartDate)"
                      @click:append="startDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="survey.startDate"
                  no-title
                  class="ma-0"
                  @input="startDateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="endDate"
                  >
                    <v-text-field
                      v-model="formatedEndDate"
                      v-mask="'##/##/####'"
                      label="Data final"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="survey.endDate = dateFormatIso(formatedEndDate)"
                      @click:append="endDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="survey.endDate"
                  no-title
                  class="ma-0"
                  @input="endDateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="description"
              >
                <v-textarea
                  v-model="survey.description"
                  hide-details="auto"
                  label="Descrição"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <validation-provider
            v-slot="{ errors }"
            vid="typesOfContacts"
          >
            <v-row class="mt-5">
                <v-col
                  v-for="typeOfContact in typesOfContacts"
                  :key="typeOfContact.id"
                  cols="4"
                  class="pb-0 pt-1"
                >
                  <v-checkbox
                    v-model="survey.typesOfContacts"
                    hide-details="auto"
                    class="mt-0"
                    :label="typeOfContact.description"
                    :value="typeOfContact.id"
                    :error="errors.length > 0"
                  ></v-checkbox>
                </v-col>
            </v-row>
            <v-row
              v-if="errors.length > 0"
              no-gutters
            >
              <v-col
                no-gutters
                class="text-left"
              >
                <span class="body-2 error--text">
                  {{ errors[0] }}
                </span>
              </v-col>
            </v-row>
          </validation-provider>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import surveysApi from '@/api/surveys'
  import typesOfContactssApi from '@/api/types-of-contacts'
  import dateFormat from '@/utils/date-format'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      surveyId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        startDateMenu: false,
        endDateMenu: false,
        formatedStartDate: null,
        formatedEndDate: null,
        survey: {
          typesOfContacts: [],
        },
        typesOfContacts: [],
        dateFormatIso: dateFormat.iso,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      title () {
        return this.surveyId ? 'Editar' : 'Adicionar'
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
      'survey.startDate': {
        immediate: true,
        handler (newVal) {
          this.formatedStartDate = dateFormat.ptBr(newVal)
        },
      },
      'survey.endDate': {
        immediate: true,
        handler (newVal) {
          this.formatedEndDate = dateFormat.ptBr(newVal)
        },
      },
    },

    mounted () {
      this.loadTypesOfContacts()
    },

    methods: {

      async load () {
        try {
          this.survey = {
            typesOfContacts: [],
            startDate: null,
            endDate: null
          }

          if (!this.surveyId) {
            return
          }

          this.loading = true

          const response = await surveysApi.get(this.surveyId)

          const survey = response.data.survey
          survey.startDate = survey.startDate.substring(0, 10)

          if (survey.endDate) {
            survey.endDate = survey.endDate.substring(0, 10)
          }

          this.survey = survey
          this.survey.typesOfContacts = this.survey.typesOfContacts.map((type) => type.id)
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          if (this.surveyId) {
            await surveysApi.update(this.surveyId, this.survey)
          } else {
            await surveysApi.insert(this.survey)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.surveyId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response && e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

      async loadTypesOfContacts () {
        try {
          this.loading = true

          const response = await typesOfContactssApi.list({
            limit: 1000,
            offset: 0,
          })

          this.typesOfContacts = response.data.typesOfContacts
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

    },

  }
</script>
